import React, { useContext } from 'react';
import {
  IconButton, Snackbar
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { ConfirmationDialog } from 'components/dialogs'
import { Event } from 'Tracker'
import { User } from 'models'
import { useRouteCanShowEmailSubscriptionDialog } from './misc';
import AppContext from 'AppContext';
import { useRecommendedBattleMapsModal } from 'recommendedBattleMapsModal';

type Props = {
  user: User
}

function LoggedInSubscribeDialog({ user }: Props) {
  const routeCanShowEmailSubscriptionDialog = useRouteCanShowEmailSubscriptionDialog()
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false)
  const [snackbarOpen, setSnackbarOpen] = React.useState(false)
  const { restClient } = useContext(AppContext)
  const { visitorCanBePromptedToSubscribe, neverPromptVisitorAgain } = useRecommendedBattleMapsModal()

  const externalMapOpened = () => {
    if (visitorCanBePromptedToSubscribe({ user })) {
      setDialogOpen(true)
    }
  }

  React.useEffect(() => {
    if (routeCanShowEmailSubscriptionDialog) {
      const ddSubscriptionId = window.tracker.subscribe(
        Event.DirectDownloadMapDownloaded, externalMapOpened
      )
      const externalSubscriptionId = window.tracker.subscribe(
        Event.ExternalMapPageOpened, externalMapOpened
      )
      return () => {
        window.tracker.unsubscribe(ddSubscriptionId)
        window.tracker.unsubscribe(externalSubscriptionId)
      }
    }
  }, [routeCanShowEmailSubscriptionDialog, user])

  React.useEffect(() => {
    if (dialogOpen) {
      window.tracker.track(Event.SuggestedMapsSubscribeModalShown)
      neverPromptVisitorAgain({ signedIn: true })
    }
  }, [dialogOpen])

  const decline = () => {
    window.tracker.track(Event.SuggestedMapsSubscribeModalDeclined);
    setDialogOpen(false)
  }

  const subscribe = () => {
    restClient.post("/email/subscriptions")
    window.tracker.track(Event.SuggestedMapsSubscribeModalSubscribed)
    setSnackbarOpen(true)
    setDialogOpen(false)
  }

  return (
    <React.Fragment>
      <ConfirmationDialog
        open={dialogOpen}
        onClose={decline}
        title="Free maps to inspire you every week"
        cancelLabel="No thanks"
        confirmLabel="Subscribe me"
        onConfirm={subscribe}
        contentText="Looking for new ideas for your next session?  Subscribe to our Recommended Battle Maps emails and you&apos;ll receive new maps in your inbox every week."
      />
      <Snackbar
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setSnackbarOpen(false)}
          >
            <Close fontSize="small" />
          </IconButton>
        }
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={2000}
        message="You have been subscribed."
      />
    </React.Fragment>
  )
}

export default LoggedInSubscribeDialog
