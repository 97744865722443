import { User } from 'models'
import Cookies from 'js-cookie'
import { CookieName } from 'storage'
import { useContext } from 'react';
import AppContext from 'AppContext';
import { AppLoaderContext } from 'AppLoader';

type VisitorCanBePromptedProps = undefined | { user?: User }

type NeverPromptProps = {
  signedIn: boolean
}

type RecommendedBattleMapsModal = {
  visitorCanBePromptedToSubscribe: (props?: VisitorCanBePromptedProps) => boolean
  neverPromptVisitorAgain: (props: NeverPromptProps) => void
  pauseVisitorPrompts: (days: number) => void
}

export const useRecommendedBattleMapsModal = (): RecommendedBattleMapsModal => {
  const { restClient } = useContext(AppContext)
  const { refreshSession } = useContext(AppLoaderContext)

  const visitorCanBePromptedToSubscribe = (props?: VisitorCanBePromptedProps): boolean => {
    if (props?.user) {
      return !props.user.promptedToSubscribeToBlogEmails
    } else {
      return !Cookies.get(CookieName.NotLoggedInSubscribeDialog)
    }
  }

  const neverPromptVisitorAgain = async ({ signedIn }: NeverPromptProps) => {
    if (signedIn) {
        await restClient.put(
          "/user", { prompted_to_subscribe_to_blog_emails: true }
        )
        refreshSession()
    } else {
      Cookies.set(CookieName.NotLoggedInSubscribeDialog, '1', { expires: 3650 })
    }
  }

  const pauseVisitorPrompts = (days: number) => {
    Cookies.set(CookieName.NotLoggedInSubscribeDialog, '1', { expires: days })
  }

  return {
    visitorCanBePromptedToSubscribe,
    neverPromptVisitorAgain,
    pauseVisitorPrompts
  }
}