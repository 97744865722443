import React from 'react';
import { Event } from 'Tracker'
import { useRouteCanShowEmailSubscriptionDialog } from '../misc';
import Dialog from './Dialog'
import Snackbar from './Snackbar'
import { useRecommendedBattleMapsModal } from 'recommendedBattleMapsModal';

function NotLoggedInSubscribeDialog() {
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false)
  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false)
  const routeCanShowEmailSubscriptionDialog = useRouteCanShowEmailSubscriptionDialog()
  const { visitorCanBePromptedToSubscribe } = useRecommendedBattleMapsModal()

  const externalMapOpened = () => {
    if (visitorCanBePromptedToSubscribe()) {
      setDialogOpen(true)
      window.tracker.track(Event.SuggestedMapsSubscribeModalShown)
    }
  }

  React.useEffect(() => {
    if (routeCanShowEmailSubscriptionDialog) {
      const subscriptionId = window.tracker.subscribe(
        Event.ExternalMapPageOpened, externalMapOpened
      )
      return () => window.tracker.unsubscribe(subscriptionId)
    }
  }, [routeCanShowEmailSubscriptionDialog])

  return (
    <>
      <Dialog
        setSnackbarOpen={setSnackbarOpen}
        setDialogOpen={setDialogOpen}
        dialogOpen={dialogOpen}
      />
      <Snackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
      />
    </>
  )
}

export default NotLoggedInSubscribeDialog
